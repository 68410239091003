import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function PlayerResultsTable({selectedPlayer, setSelectedPlayer, setSearchTerm}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const player = params.get('player');
    const [results, setResults] = useState([]);
    const [winsLosses, setH2hScore] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedPlayer) {
            fetch(`https://betapi.tnx-solutions.ch/api/v1/player/results/${selectedPlayer?.person?.id}`)
                .then((response) => response.json())
                .then((data) => {
                    const sortedData = data.sort((a, b) => new Date(b.result.date) - new Date(a.result.date));
                    setResults(sortedData);
                    const wins = data.filter(row => row.result.playerWinnerCode === "S" || row.result.playerWinnerCode === "W").length;
                    const losses = data.filter(row => row.result.playerWinnerCode === "N" || row.result.playerWinnerCode === "Z").length;
                    setH2hScore(`${wins}-${losses}`);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [selectedPlayer]);

    const getResult = (row) => {
        const sets = [];
        for (let i = 1; i <= 5; i++) {
            const playerGames = row[`playerSet${i}WonGames`];
            const adversaryGames = row[`adversarySet${i}WonGames`];
            if (playerGames !== -1 && adversaryGames !== -1) {
                sets.push(`${playerGames}-${adversaryGames}`);
            }
        }
        return sets.join(', ');
    };

    const handleRowClick = (adversaryPersonId) => {
        fetch(`https://betapi.tnx-solutions.ch/api/v1/player/${adversaryPersonId}`)
            .then((response) => response.json())
            .then((data) => {
                setSelectedPlayer(data[0]);
                navigate(`?player=${data[0]?.person?.id}`);
                setSearchTerm('');
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const getClassification = (result) => {
        // If rank isn't available, check adversary classification and ranking
        if (result.adversary?.classification && result.adversary?.ranking) {
            return `${result.adversary.classification} (${result.adversary.ranking})`;
        }

        // Check if result.rank is available
        if (result.rank) {
            return result.rank;
        }

        // If neither is available, return 'N/A'
        return 'N/A';
    };

    const getCompValue = (result) => {
        if (result.adversary?.competitionValue4L) {
            return result.adversary.competitionValue4L;
        } else if (result?.Asr_AdversaryValue_f) {
            return result.Asr_AdversaryValue_f.toFixed(3);
        } else {
            return '';
        }
    };

    return (
        <>
            <Typography variant="h8" component="div" gutterBottom align="center" sx={{mb: 2}}>
                Siege : Niederlagen {winsLosses}
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: {xs: '30px', sm: '600px'}, maxWidth: {xs: '300px', sm: '600px'}}}
                       aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Gegner</TableCell>
                            <TableCell>Klassierung</TableCell>
                            <TableCell>Wettk. Wert</TableCell>
                            <TableCell>Live</TableCell>
                            <TableCell>Resultat</TableCell>
                            <TableCell>Code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((data) => (
                            <TableRow key={data.result.id}>
                                <TableCell>{new Date(data.result.date).toLocaleDateString()}</TableCell>
                                <TableCell
                                    onClick={() => handleRowClick(data.result.adversaryPersonId)}
                                    sx={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        '&:hover': {
                                            backgroundColor: '#e0e0e0',
                                        },
                                    }}
                                >
                                    {`${data.result.adversaryFirstname}  ${data.result.adversaryLastname}`}
                                </TableCell>
                                <TableCell>
                                    {getClassification(data.result)}
                                </TableCell>
                                <TableCell>
                                    {getCompValue(data.result) || ''}
                                </TableCell>
                                <TableCell>
                                    {data.result.adversary?.ranking && (
                                        <Box>
                                            <Typography variant="body2" component="div">
                                                {data.liveRanking}
                                            </Typography>
                                            {(
                                                <Typography
                                                    variant="body2"
                                                    component="div"
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        lineHeight: '1.2', // Adjust line height for consistency
                                                    }}
                                                >
                                                    {data.liveRanking < data.result.adversary.ranking ? (
                                                        <>
                                                            (+{data.result.adversary.ranking - data.liveRanking}
                                                            <ArrowUpwardIcon fontSize="inherit" sx={{
                                                                ml: 0.3,
                                                                color: 'green',
                                                                fontSize: '0.8rem'
                                                            }}/>)
                                                        </>
                                                    ) : (
                                                        <>
                                                            (-{data.liveRanking - data.result.adversary.ranking}
                                                            <ArrowDownwardIcon fontSize="inherit" sx={{
                                                                ml: 0.3,
                                                                color: 'red',
                                                                fontSize: '0.8rem'
                                                            }}/>)
                                                        </>
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                </TableCell>
                                <TableCell>{getResult(data.result)}</TableCell>
                                <TableCell>{data.result.playerWinnerCode}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default PlayerResultsTable;
